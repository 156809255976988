'use client';

import getSessionId from '@/helpers/getSessionId/getSessionId';
import { useEffect, useRef } from 'react';

const HeapData = () => {
	const identified = useRef(false);
	const count = useRef(0);

	useEffect(() => {
		const identify = () => {
			if (window.heap && !identified.current) {
				const id = getSessionId();
				window.heap.addUserProperties({
					MTAAnonymousID: id ?? '',
				});
				window.heap.identify(id);

				if (id) {
					identified.current = true;
				}
			}

			if (!identified.current && count.current++ < 10) {
				setTimeout(identify, 100);
			}
		};

		identify();
	}, []);

	return null;
};

export default HeapData;
