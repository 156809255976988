import { CHATLIO_DEALER_WIDGET_ID } from './constants';

export const triggerDealerChat = () => {
	if (!window._chatlio) return;

	// switch to dealer chat
	window._chatlio.switchWidget?.(CHATLIO_DEALER_WIDGET_ID);

	// switching resets chat. per the docs, we have to listen for a new ready event before doing anything
	document.addEventListener(
		'chatlio.ready',
		() => {
			window._chatlio.open();
		},
		{ once: true },
	);
};
