export type EnumMap<Payload extends { [index in keyof Payload]: unknown }> = {
	[Key in keyof Payload]: Payload[Key];
};

export const UserProfileAttributes = {
	MTA_AID: 'mtaAid',
	MTA_SID: 'mtaSid',
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	EMAIL: 'email',
	ZIP: 'zip',
	ADDRESS: 'address',
	PHONE: 'phone',
	CITY: 'city',
	STATE: 'state',
} as const;

export type UserProfileAttributesMap = EnumMap<
	typeof UserProfileAttributes
>[keyof EnumMap<typeof UserProfileAttributes>];

export const UserProfileStorageKey = 'user_profile' as const;

export interface UserProfileData {
	[UserProfileAttributes.MTA_AID]: string;
	[UserProfileAttributes.MTA_SID]: string;
	[UserProfileAttributes.FIRST_NAME]: string;
	[UserProfileAttributes.LAST_NAME]: string;
	[UserProfileAttributes.EMAIL]: string;
	[UserProfileAttributes.ZIP]: string;
	[UserProfileAttributes.ADDRESS]: string;
	[UserProfileAttributes.PHONE]: string;
	[UserProfileAttributes.CITY]: string;
	[UserProfileAttributes.STATE]: string;
}

export default class UserProfile {
	private _mtaAid: string;
	private _mtaSid: string;
	private _firstName: string;
	private _lastName: string;
	private _email: string;
	private _zip: string;
	private _address: string;
	private _phone: string;
	private _city: string;
	private _state: string;

	constructor() {
		this._mtaAid = '';
		this._mtaSid = '';
		this._firstName = '';
		this._lastName = '';
		this._email = '';
		this._zip = '';
		this._address = '';
		this._phone = '';
		this._city = '';
		this._state = '';
		this.initalizeFromLocalStorage();
		this.initializeFromCookies();
	}

	private initalizeFromLocalStorage() {
		if (typeof localStorage === 'undefined') return;

		const userProfile = localStorage.getItem(UserProfileStorageKey);
		if (userProfile) {
			const UP = JSON.parse(userProfile);
			if (this.isUserProfileData(UP)) {
				this._mtaAid = UP[UserProfileAttributes.MTA_AID];
				this._mtaSid = UP[UserProfileAttributes.MTA_SID];
				this._firstName = UP[UserProfileAttributes.FIRST_NAME];
				this._lastName = UP[UserProfileAttributes.LAST_NAME];
				this._email = UP[UserProfileAttributes.EMAIL];
				this._zip = UP[UserProfileAttributes.ZIP];
				this._address = UP[UserProfileAttributes.ADDRESS];
				this._phone = UP[UserProfileAttributes.PHONE];
				this._city = UP[UserProfileAttributes.CITY];
				this._state = UP[UserProfileAttributes.STATE];
			}
		}
	}

	private initializeFromCookies() {
		// starts with _mta_aid= followed by any character that is not a semicolon or a space
		const mtaAidPattern = /_mta_aid=(\S[^;]*)/;
		const mtaSidPattern = /_mta_sid=(\S[^;]*)/;

		// because next runs sometimes in node, we need to check if document is defined
		if (typeof document === 'undefined') return;

		const [, mta_aid] = document.cookie.match(mtaAidPattern) ?? [];
		const [, mta_sid] = document.cookie.match(mtaSidPattern) ?? [];

		if (mta_aid) this._mtaAid = mta_aid;
		if (mta_sid) this._mtaSid = mta_sid;
	}

	private isUserProfileData(data: unknown): data is UserProfileData {
		return (
			typeof data === 'object' &&
			data !== null &&
			UserProfileAttributes.MTA_AID in data &&
			UserProfileAttributes.MTA_SID in data &&
			UserProfileAttributes.FIRST_NAME in data &&
			UserProfileAttributes.LAST_NAME in data &&
			UserProfileAttributes.EMAIL in data &&
			UserProfileAttributes.ZIP in data &&
			UserProfileAttributes.ADDRESS in data &&
			UserProfileAttributes.PHONE in data &&
			UserProfileAttributes.STATE in data &&
			UserProfileAttributes.CITY in data
		);
	}

	private saveToLocalStorage() {
		if (typeof localStorage === 'undefined') return;
		localStorage.setItem(UserProfileStorageKey, JSON.stringify(this.profile));
	}

	/****************************************
	 * Profile with Proxy
	 * This is the main way to access the user profile. It uses a Proxy that makes
	 * the object readonly, and ensures that when a property on the profile is
	 * acccessed the new updated value is returned. Without using a proxy, the
	 * returned object would be a snapshot of the profile at the time of creation.
	 */
	get profile(): UserProfileData {
		// This ojbect instructs the proxy how to handle getting properties from
		// the profile object.
		const handler = {
			get: (
				target: UserProfileData,
				prop: UserProfileAttributesMap,
				receiver: unknown,
			) => {
				switch (prop) {
					case UserProfileAttributes.MTA_AID:
						return this._mtaAid;
					case UserProfileAttributes.MTA_SID:
						return this._mtaSid;
					case UserProfileAttributes.FIRST_NAME:
						return this._firstName;
					case UserProfileAttributes.LAST_NAME:
						return this._lastName;
					case UserProfileAttributes.EMAIL:
						return this._email;
					case UserProfileAttributes.ZIP:
						return this._zip;
					case UserProfileAttributes.ADDRESS:
						return this._address;
					case UserProfileAttributes.PHONE:
						return this._phone;
					case UserProfileAttributes.CITY:
						return this._city;
					default:
						return Reflect.get(target, prop, receiver);
				}
			},
		};

		// This creates a proxy object that uses the handler object to determine
		// how to handle getting properties from the profile object plus a default
		// object to proxy.
		const profileProxy = new Proxy<UserProfileData>(
			{
				[UserProfileAttributes.MTA_AID]: this._mtaAid,
				[UserProfileAttributes.MTA_SID]: this._mtaSid,
				[UserProfileAttributes.FIRST_NAME]: this._firstName,
				[UserProfileAttributes.LAST_NAME]: this._lastName,
				[UserProfileAttributes.EMAIL]: this._email,
				[UserProfileAttributes.ZIP]: this._zip,
				[UserProfileAttributes.ADDRESS]: this._address,
				[UserProfileAttributes.PHONE]: this._phone,
				[UserProfileAttributes.CITY]: this._city,
				[UserProfileAttributes.STATE]: this._state,
			},
			handler,
		);
		return profileProxy;
	}

	/****************************************
	 * MTA AID readonly
	 */
	get mtaAid(): string {
		return this._mtaAid;
	}

	set mtaAid(_: string) {
		return;
	}

	/****************************************
	 * MTA SID readonly
	 */
	get mtaSid(): string {
		return this._mtaSid;
	}

	set mtaSid(_: string) {
		return;
	}

	/****************************************
	 * First Name
	 */
	get firstName(): string {
		return this._firstName;
	}

	set firstName(value: string) {
		this._firstName = value;
		this.saveToLocalStorage();
	}

	/******************************************
	 * Last Name
	 */
	get lastName(): string {
		return this._lastName;
	}

	set lastName(value: string) {
		this._lastName = value;
		this.saveToLocalStorage();
	}

	/******************************************
	 * Email
	 */
	get email(): string {
		return this._email;
	}

	set email(value: string) {
		this._email = value;
		this.saveToLocalStorage();
	}

	/******************************************
	 * Zip
	 */
	get zip(): string {
		return this._zip;
	}

	set zip(value: string) {
		this._zip = value;
		this.saveToLocalStorage();
	}

	/******************************************
	 * Address
	 */
	get address(): string {
		return this._address;
	}

	set address(value: string) {
		this._address = value;
		this.saveToLocalStorage();
	}

	/******************************************
	 * City
	 */
	get city(): string {
		return this._city;
	}

	set city(value: string) {
		this._city = value;
		this.saveToLocalStorage();
	}

	/******************************************
	 * State
	 */
	get state(): string {
		return this._state;
	}

	set state(value: string) {
		this._state = value;
		this.saveToLocalStorage();
	}

	/******************************************
	 * Phone
	 */
	get phone(): string {
		return this._phone;
	}

	set phone(value: string) {
		this._phone = value;
		this.saveToLocalStorage();
	}
}
