'use client';

import { useEffect, useRef } from 'react';

const ChatlioListeners = () => {
	// ref to track if chatlio is ready
	const readyRef = useRef(false);

	useEffect(() => {
		// hides the peek message after a trigger to prevent a UI
		// bug that would show the peek message under the open chat
		// also, actually opens the chat post trigger
		const postTrigger = () => {
			if (readyRef.current) {
				const chatlioPeek = document.getElementsByClassName(
					'chatlio-peek-message-container',
				)[0];
				chatlioPeek?.classList.replace(
					'show-chatlio-peek-message',
					'hide-chatlio-peek-message',
				);
				window._chatlio.open();
			}
		};

		const postCollapse = () => {
			if (readyRef.current) {
				window._chatlio.hide();
			}
		};

		const chatlioReady = () => {
			readyRef.current = true;
		};

		const postMessageReceived = () => {
			if (readyRef.current) {
				window._chatlio.open();
			}
		};

		// add event listeners
		document.addEventListener('chatlio.trigger', postTrigger);
		document.addEventListener('chatlio.collapsed', postCollapse);
		document.addEventListener('chatlio.ready', chatlioReady);
		document.addEventListener('chatlio.messageReceived', postMessageReceived);

		return () => {
			// on unmount, remove event listeners
			document.removeEventListener('chatlio.trigger', postTrigger);
			document.removeEventListener('chatlio.collapsed', postCollapse);
			document.removeEventListener('chatlio.ready', chatlioReady);
			document.removeEventListener(
				'chatlio.messageReceived',
				postMessageReceived,
			);
		};
	}, []);

	return null;
};

export default ChatlioListeners;
