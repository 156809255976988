'use client';

import React, { useEffect, useState } from 'react';

import { SplitFactoryProvider } from '@splitsoftware/splitio-react';

import getSessionId from '@/helpers/getSessionId/getSessionId';
import { getLocationInfo } from '@/helpers/mistService';
import {
	SplitRumAgent,
	webVitals,
	routeChanges,
	tti,
} from '@splitsoftware/browser-rum-agent';

import profiler from '@/helpers/userProfile';

interface SplitProviderProps {
	children: React.ReactNode;
}

function SplitProvider(props: SplitProviderProps) {
	const { children } = props;

	const authorizationKey = process.env.NEXT_PUBLIC_CLIENT_SPLIT_API_KEY;
	const userKey = getSessionId() || 'anonymous-user';

	const [locationInfo, setLocationInfo] = useState({
		zip: '',
		city: '',
		state: '',
	});

	if (!authorizationKey) {
		console.warn('Split API key not detected');
	}

	// Initialize the Split RUM Agent
	useEffect(() => {
		const initializeSplitRumAgent = () => {
			SplitRumAgent.setup(authorizationKey || '').addIdentities([
				{ key: userKey, trafficType: 'user' },
			]);

			SplitRumAgent.register(webVitals());
			SplitRumAgent.register(tti());
			SplitRumAgent.register(routeChanges());
		};

		initializeSplitRumAgent();
	}, [authorizationKey, userKey]);

	const sdkConfig: SplitIO.IBrowserSettings = {
		core: {
			authorizationKey: process.env.NEXT_PUBLIC_CLIENT_SPLIT_API_KEY || '',
			key: userKey,
		},
		//	debug: process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev',
	};

	useEffect(() => {
		const [userProfile] = profiler();
		const setLocationContent = async () => {
			const data = await getLocationInfo();

			setLocationInfo({
				zip: data?.postalCode || '',
				city: data?.city || '',
				state: data?.state || '',
			});
		};

		//The user profile should take precedence over the data from Mist
		if (userProfile.city && userProfile.state && userProfile.zip) {
			setLocationInfo({
				city: userProfile.city,
				state: userProfile.state,
				zip: userProfile.zip,
			});
		} else {
			setLocationContent();
		}
	}, []);

	return (
		<SplitFactoryProvider config={sdkConfig} attributes={locationInfo}>
			{children}
		</SplitFactoryProvider>
	);
}

export default SplitProvider;
