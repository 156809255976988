'use client';

import Link from 'next/link';
import cn from 'classnames';
import Balancer from 'react-wrap-balancer';
import { usePathname } from 'next/navigation';

import { HvacLogoReverse } from '@/components/logos/HvacLogoReverse';
import { triggerDealerChat } from '@/helpers/chatlio/triggerDealerChat';
import { FooterLinkItem } from '../FooterLink/types';
import { FooterLink } from '../FooterLink';
import { SocialLinks } from '../SocialLinks';

import { useSplitTreatments } from '@splitsoftware/splitio-react';

const navLinks: FooterLinkItem[] = [
	{
		href: '/quote',
		label: 'Replace my HVAC',
	},
	{
		href: '/quotescore',
		label: 'Score my Quote',
	},
	{
		href: '/tools',
		label: 'Calculators',
	},
	{
		href: '/expert-advice',
		label: 'Learn',
	},

	{
		href: '/about-us',
		label: 'About Us',
	},
	{
		callback: () => triggerDealerChat(),
		label: 'Join Our Network',
	},
	{
		href: '/privacy-policy',
		label: 'Privacy Policy',
		className: 'lg:ml-auto',
	},
	{
		href: '/terms-of-use',
		label: 'Terms of Use',
	},
];

const info = {
	site: 'HVAC.com',
	address: '1101 Red Ventures Dr, Fort Mill, SC 29707',
	phone: '803-547-4301',
	phoneTest: '(803)-547-4302',
	copyright:
		'HVAC.com – a Magenta Technologies LLC Company, All Rights Reserved',
};

const Footer = () => {
	const pathname = usePathname();

	const {
		treatments: { FooterPhoneNumber },
	} = useSplitTreatments({ names: ['FooterPhoneNumber'] });

	if (pathname?.match('/quote/flow') || pathname?.match('/quotescore/flow/')) {
		return <footer></footer>;
	}

	return (
		<footer className="mt-auto print:hidden">
			<div className={cn('gradient-nightmare')}>
				<div
					className={cn(
						'w-full max-w-7xl',
						'mx-auto px-4 py-8 md:px-6 2xl:px-0',
					)}
				>
					<div
						className={cn(
							'flex flex-col lg:flex-row',
							'border-slate-300 lg:border-b',
							'items-center lg:justify-between',
							'lg:pb-7',
						)}
					>
						<Link href={'/'}>
							<HvacLogoReverse className="h-9" />
						</Link>
						<SocialLinks containerClass="hidden lg:flex" />
					</div>
					<nav className={cn('pt-8')}>
						<ul
							className={cn(
								'flex flex-col items-center lg:flex-row',
								'gap-4 lg:gap-7',
							)}
						>
							{navLinks.map((link) => (
								<FooterLink key={link.label} link={link} />
							))}
						</ul>
					</nav>

					<div
						className={cn(
							'hidden flex-row justify-between lg:flex',
							'lg:mt-7',
							'text-xs tracking-tight text-slate-600 2xl:text-base',
						)}
					>
						<p>
							{info.site} • {info.address}
							{FooterPhoneNumber.treatment === 'show'
								? ` • ${info.phoneTest}`
								: ` • ${info.phone}`}
						</p>

						<p>
							&copy; {new Date().getFullYear()} {info.copyright}
						</p>
					</div>

					{/* For mobile only */}
					<SocialLinks containerClass="flex justify-center py-8 lg:hidden" />
					<div
						className={cn(
							'text-slate-600',
							'lg:hidden',
							'text-center',
							'border-b border-slate-300',
							'pb-10',
						)}
					>
						<p>{info.site}</p>
						<p>{info.address.replace(',', '\n')}</p>

						{FooterPhoneNumber.treatment === 'show' ? (
							<p>
								<Link href={`tel:${info.phone}`} className="text-black">
									{info.phoneTest}
								</Link>
							</p>
						) : (
							<p>
								<Link href={`tel:${info.phone}`} className="text-black">
									{info.phone}
								</Link>
							</p>
						)}
					</div>
					<div
						className={cn('text-slate-400', 'lg:hidden', 'text-center', 'py-6')}
					>
						<p>
							<Balancer>
								&copy; {new Date().getFullYear()} {info.copyright}
							</Balancer>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
