'use client';

import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import markerSDK from '@marker.io/browser';
import { initializeLocation } from '@/helpers/mistService';
import profiler, { UserProfileAttributesMap } from '@/helpers/userProfile';

/*
 * GlobalFunctions - Client component that executes specific functions
 */
const ANONYMOUS_ID_NAME = '_mta_aid';
const SESSION_ID_NAME = '_mta_sid';

const GlobalFunctions = () => {
	const [, updateProfile] = profiler();

	useEffect(() => {
		function setCookieAndUpdateProfile(
			cookieName: string,
			profileKey: UserProfileAttributesMap,
			expires: number,
		) {
			let id = Cookies.get(cookieName);
			if (!id) {
				id = uuidv4();
				Cookies.set(cookieName, id, {
					expires,
					sameSite: 'strict',
				});
			}
			updateProfile(profileKey, id);
		}

		function generateMtaCookies() {
			setCookieAndUpdateProfile(ANONYMOUS_ID_NAME, 'mtaAid', 365);
			setCookieAndUpdateProfile(SESSION_ID_NAME, 'mtaSid', 1 / 48);
		}

		generateMtaCookies();
		initializeLocation();

		// show marker.io widget in Amplify preview and staging environments
		if (
			process.env.NEXT_PUBLIC_ENVIRONMENT === 'preview' ||
			process.env.NEXT_PUBLIC_ENVIRONMENT === 'stg'
		) {
			initializeMarker();
		}
	}, [updateProfile]);

	function initializeMarker() {
		markerSDK.loadWidget({
			project: '6512fcefab54f40fb4375c04',
		});
		return null;
	}

	return null;
};

export default GlobalFunctions;
