// Shamelessly stolen from https://trackingchef.com/google-analytics/how-to-add-session-id-to-ga4-measurement-protocol-events/

function getSessionId(): string | null {
	const pattern = /_mta_aid=([a-zA-Z0-9-]*)/;
	// because next runs sometimes in node, we need to check if document is defined
	if (typeof document === 'undefined') {
		return null;
	}

	const match = document.cookie.match(pattern);
	const sessionId = match && match[1];

	if (!sessionId) {
		return null;
	}

	return sessionId;
}

export default getSessionId;
